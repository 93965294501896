import React from "react"
import { Menu, styled } from "@mui/material"

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    backgroundColor: "#242424",
  },
})

export default function NavbarMenu({
  anchorEl,
  isOpen,
  handleClose,
  children,
}) {
  return (
    <StyledMenu
      elevation={0}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </StyledMenu>
  )
}
