import { useMediaQuery } from "@mui/material";

// Breakpoints that can be used for the media query
const breakpoints = {
  xs: "0px",
  sm: "600px",
  cardBreakpoint: "725px",
  md: "960px",
  lg: "1280px",
  xl: "1920px",
};

const useMediaQueries = (breakpoint) => {
  return useMediaQuery(`(max-width: ${breakpoints[breakpoint]})`);
};

export { useMediaQueries };
