import React from "react"
import { List, ListItem } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "gatsby"

const useStyles = makeStyles({
  listItem: {
    height: 50,
  },
  navLink: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.87)",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
})

export default function MSList({ handleSidebarToggle }) {
  const classes = useStyles()

  return (
    <List disablePadding>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/getting-started"
        >
          Getting Started
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/management-integration-guide"
        >
          Management Integration Guide
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/s2p-integration-guide"
        >
          System-to-Person Integration Guide
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/working-with-webhooks"
        >
          System-to-Person Webhooks
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/custom-contact"
        >
          Custom Contact Forms
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/media-shuttle/api-documentation"
        >
          API Documentation
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
           className={classes.navLink}
           onClick={handleSidebarToggle}
           to="/media-shuttle/media-shuttle-sdk"
        >
            Media Shuttle SDK
        </Link>
      </ListItem>
    </List>
  )
}
