/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Seo from "../components/Seo"
import * as styles from "./layout.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import "./global.css"

const Layout = ({ children }) => {

  return (
      <div className={styles.container}>
        <Seo />
        <main className={styles.main}>
          <Navbar />
          <div className={styles.content}>{children}</div>
          <Footer />
        </main>
      </div>
  );
}

export default Layout;
