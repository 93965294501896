import React, { useState } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Drawer, List, ListItem, Divider } from "@mui/material";
import { Link } from "gatsby";
import Accordion from "./accordion";
import JetList from "./jetList";
import MAList from "./maList";
import MSList from "./msList";

const Menu = styled(MenuIcon)({
  color: "white",
  margin: "0 0.5em",
  marginTop: "1.5em",
  alignSelf: "center",
});

const StyledList = styled(List)({
  width: "100%",
  maxWidth: 360,
  textAlign: "left",
  paddingTop: "4em",
});

const StyledListItem = styled(ListItem)({
  height: 50,
  paddingY: "0", // Apply paddingY: "0" directly to ListItem
});

const NavLink = styled(Link)({
  fontSize: "14px",
  color: "rgba(0,0,0,0.87)",
  textDecoration: "none",
  "&:hover": {
    color: "black",
    textDecoration: "underline",
  },
});

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Menu onClick={handleToggle} />
      <Drawer
        anchor="left"
        open={isOpen}
        variant="temporary"
        onClose={handleToggle}
        PaperProps={{
          sx: {
            width: 270,
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <StyledList>
          <StyledListItem>
            <NavLink onClick={handleToggle} to="/">
              Home
            </NavLink>
          </StyledListItem>
          <Divider />
          <Accordion
            productName="Jet"
            productList={<JetList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <Accordion
            productName="Media Shuttle"
            productList={<MSList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <Accordion
            productName="Flight Deck"
            productList={<MAList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <StyledListItem>
            <NavLink onClick={handleToggle} to="/release-notes">
              Release Notes
            </NavLink>
          </StyledListItem>
        </StyledList>
      </Drawer>
    </div>
  );
}
