import React from "react"
import { MenuItem } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import Menu from "../index"
import { Link } from "gatsby"

const useStyles = makeStyles({
  navLink: {
    color: "white",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontWeight: "200",
    "&:hover": {
      color: "white",
    },
  },
})

export default function ManagerAgentsMenu({ isOpen, anchorEl, handleClose }) {
  const classes = useStyles()
  return (
    <Menu isOpen={isOpen} anchorEl={anchorEl} handleClose={handleClose}>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/get-started-with-rest"
        >
          Getting Started
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/flight-deck/job-scheduling">
          Configuring Scheduled Jobs
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/bandwidth-management"
        >
          Bandwidth Management
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/flight-deck/job-examples">
          API Job Examples
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/signiant-platform/roles">
        User Roles on the Signiant Platform
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/api-documentation"
        >
            Manager API Reference
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/flight-deck-api-documentation"
        >
             Flight Deck API Reference
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/soap-development-guide"
        >
            SOAP API Development Guide
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/flight-deck/fims-development-guide"
        >
            FIMS API Development Guide
        </Link>
      </MenuItem>
    </Menu>
  )
}
