import React from "react";
import Script from "react-load-script";
import { Input, InputAdornment, styled } from "@mui/material";
import { navigate } from "gatsby";

const StyledInput = styled(Input)({
  fontSize: "14px",
  backgroundColor: "#5b5b5b",
  color: "white",
  minWidth: "200px",
  width: "100%",
  borderRadius: "2px",
  padding: "0px 5px",
  marginBottom: "5px",
});

const InputWrapper = styled('div')(({ theme }) => ({
  marginLeft: "auto",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));

export default function DocSearch({ inputAdornment }) {

  const enableSearch = () => {
    window.docsearch({
      apiKey: "d2fe26cfe3f0c4d6ceb5e02f2de23b73",
      indexName: "developer_signiant",
      inputSelector: "#algolia-doc-search",
      debug: true,
      handleSelected: (input, event, suggestion, datasetNumber, context) => {
        const regex = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/g;
        let match = regex.exec(suggestion.url);
        if (match === null || match.length < 2) {
          return "/";
        }

        const path = match[1].replace(".html", "");
        navigate(path);
      },
    });
  };

  return (
    <>
      <Script
        url="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js"
        onLoad={enableSearch}
      />
      <InputWrapper>
        <StyledInput
          placeholder="Search Documentation"
          variant="outlined"
          id="algolia-doc-search"
          startAdornment={
            <InputAdornment sx={{color: "white"}} position="start">{inputAdornment}</InputAdornment>
          }
        />
      </InputWrapper>
    </>
  );
}
