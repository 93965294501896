import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { ListItem, Collapse, Divider } from "@mui/material";

const StyledListItem = styled(ListItem)({
  height: 50,
  paddingY: "0",
});

const ProductName = styled('p')({
  margin: 0,
  fontSize: "14px",
  color: "rgba(0,0,0,0.87)",
});

const StyledKeyboardArrowRight = styled(KeyboardArrowRight)({
  marginLeft: "auto",
});

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)({
  marginLeft: "auto",
});

const SubDivider = styled(Divider)({
  marginLeft: "1em",
});

const ListWrapper = styled('div')({
  marginLeft: "1.25em",
});

export default function Accordion({ productName, productList }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <StyledListItem onClick={handleAccordionToggle}>
        <ProductName>{productName}</ProductName>
        {isOpen ? (
            <StyledKeyboardArrowRight />
        ) : (
            <StyledKeyboardArrowDown />
        )}
      </StyledListItem>
      <Collapse in={isOpen} timeout="auto">
        <SubDivider />
        <ListWrapper>{productList}</ListWrapper>
      </Collapse>
    </>
  );
}
