import React, { useState } from "react"
import { Link } from "gatsby"
import JetMenu from "../../Menu/JetMenu"
import MediaShuttleMenu from "../../Menu/MediaShuttleMenu"
import ManagerAgentsMenu from "../../Menu/ManagerAgentsMenu"
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"

const LinkContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  flexGrow: 1,
  height: "25px",
})

const NavLink = styled(Typography)({
  color: "white",
  border: "none",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  fontSize: "14px",
  display: "flex",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.5em",
  textTransform: "uppercase",
  cursor: "pointer",

  "&:hover": {
    color: "white",
    textDecoration: "underline",
  },
})

const StyledLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  padding: "0.5em",
})

const StyledKeyboardArrowRight = styled(KeyboardArrowRight)({
  paddingLeft: "0px",
})

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)({
  padding: "0px",
})

export default function NavBarLink() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menu, setMenu] = useState("")

  const handleClick = e => {
    const label = e.target.getAttribute("data-label")
    setMenu(label)

    switch (label) {
      case "Jet":
        setAnchorEl(document.getElementById("jet-nav"))
        break
      case "Media Shuttle":
        setAnchorEl(document.getElementById("media-shuttle-nav"))
        break
      case "Flight Deck":
        setAnchorEl(document.getElementById("manager-agents-nav"))
        break
      default:
        break
    }
  }

  const handleClose = () => {
    setMenu(false)
  }

  return (
    <LinkContainer>
      <NavLink variant="body1">
        <StyledLink to="/">Home</StyledLink>
      </NavLink>
      <NavLink variant="body1">
        <StyledLink to="/release-notes">Release Notes</StyledLink>
      </NavLink>
      <NavLink id="jet-nav" onClick={handleClick} data-label="Jet">
        Jet
        {menu === "Jet" ? (
          <StyledKeyboardArrowRight onClick={handleClick} data-label="Jet" />
        ) : (
          <StyledKeyboardArrowDown onClick={handleClick} data-label="Jet" />
        )}
      </NavLink>
      <JetMenu
        isOpen={menu === "Jet"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <NavLink
        id="media-shuttle-nav"
        onClick={handleClick}
        data-label="Media Shuttle"
      >
        Media Shuttle
        {menu === "Media Shuttle" ? (
            <StyledKeyboardArrowRight
              onClick={handleClick}
              data-label="Media Shuttle"
            />
        ) : (
            <StyledKeyboardArrowDown
              onClick={handleClick}
              data-label="Media Shuttle"
            />
        )}
      </NavLink>
      <MediaShuttleMenu
        isOpen={menu === "Media Shuttle"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <NavLink
        id="manager-agents-nav"
        onClick={handleClick}
        data-label="Flight Deck"
      >
        Flight Deck
        {menu === "Flight Deck" ? (
            <StyledKeyboardArrowRight
              onClick={handleClick}
              data-label="Flight Deck"
            />
        ) : (
            <StyledKeyboardArrowDown onClick={handleClick} data-label="Flight Deck" />
        )}
      </NavLink>
      <ManagerAgentsMenu
        isOpen={menu === "Flight Deck"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </LinkContainer>
  )
}
